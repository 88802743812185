import fetch from 'isomorphic-fetch'
import { splitArrayIntoChunksOfLen } from '../utils/chunkingArray'

let { BASE_URL } = require('./config')

// FUNCTIONS
// RESHAPE MAIN DATA
const reshapeData = (data) => {
  try {
    let final = []
    let emrIdentifiers = []
    let buildingsDailyMeasures = []
    // ITERATING THROUGH ALL PROJECTS
    for (let i = 0; i < data?.payload?.length; i++) {
      // ITERATING THROUGH EMR IDENTIFIERS
      if (data?.payload[i]?.EMRs?.length > 0) {
        for (let j = 0; j < data?.payload[i]?.EMRs?.length; j++) {
          emrIdentifiers.push(data?.payload[i]?.EMRs[j]?.identifier)
        }
      }
      // ITERATING THROUGH ALL BUILDINGS
      for (let j = 0; j < data?.payload[i]?.buildingInfo?.length; j++) {
        final.push({
          EMRs: data?.payload[i]?.EMRs || [],
          address: data?.payload[i]?.address || {},
          buildingIdentifier:
            data?.payload[i]?.buildingInfo[j]?.identifier || '',
          buildingName: data?.payload[i]?.buildingInfo[j]?.name || '',
          sensorCount:
            data?.payload[i]?.buildingInfo[j]?.installedSensorCount ||
            data?.payload[i]?.buildingInfo[j]['installedSensorCount '] ||
            0,
          projectIdentifier: data?.payload[i]?.identifier || '',
          projectName: data?.payload[i]?.name || '',
          apartmentsSettings: { loading: false, expiery: '' },
          apartments: [],
          logsSettings: { loading: false, expiery: '' },
          logs: [],
          historyMeasuresSettings: { loading: false, expiery: '' },
          historyMeasures: [],
          heatingGroupsSettings: { loading: false, expiery: '' },
          heatingGroups: {},
          temporaryOffsetSettings: { loading: false, expiery: '' },
          temporaryOffset: {},
          accessRights: [],
          isFavorite: data?.payload[i]?.isFavorite || false,
        })
        buildingsDailyMeasures.push({
          identifier: data?.payload[i]?.buildingInfo[j]?.identifier || '',
          averageMeasurements: [],
        })
      }
    }

    return {
      mainData: final || [],
      emrIdentifiers: emrIdentifiers || [],
      buildingsDailyMeasures:
        splitArrayIntoChunksOfLen(buildingsDailyMeasures, 10, {
          loading: false,
          expiery: '',
        }) || [],
    }
  } catch (err) {
    return {
      mainData: [],
      emrIdentifiers: [],
      buildingsDailyMeasures: [],
    }
  }
}

// Main api that is called to loging and get the list of buildings
export const getBuildingsList = async (value) => {
  let refresher = false
  let timer = false

  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)

    let response = await fetch(
      `${BASE_URL}configuration/buildings?request=includeData`,
      {
        signal: controller.signal,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${window.btoa(
            `${value.username}:${value.password}`,
          )}`,
        },
      },
    )

    if (window.location.search === '?showlog=true') {
      console.log('get building list response', response)
    }

    if (!response.ok) {
      let errorResData = await response
      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }
    // returning parsed and reshaped response
    let resData = await response.json()
    let reshaped = reshapeData(resData)

    return reshaped
  } catch (e) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }

    return error
  }
}

// getting emr details of specific set of emrs
export const getEmrHistory = async (idsArray, value) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false

  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    if (idsArray.length > 0) {
      let response = await fetch(`${BASE_URL}historian/query`, {
        signal: controller.signal,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${window.btoa(
            `${value.username}:${value.password}`,
          )}`,
        },
        body: JSON.stringify({
          request: {
            identifiers: idsArray,
            interval: 'PT1D',
            latest: 2,
          },
        }),
      })

      if (!response.ok) {
        let errorResData = await response

        let error = {
          error: true,
          serverError: true,
          refreshError: false,
          connError: false,
          message: errorResData.statusText,
          url: errorResData.url,
          code: errorResData.status,
        }
        return error
      }

      let resData = await response.json()
      if (resData?.payload?.length > 0) {
        return resData?.payload
      } else {
        return []
      }
    }
  } catch (e) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }

    return error
  }
  return {}
}

// GET APPARTMENTS of a specific building
export const getBuildingAppartments = async (
  buildingIds,
  value,
  data,
  selectedIndex,
) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false

  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    if (buildingIds.length > 0) {
      let response = await fetch(`${BASE_URL}configuration/apartments`, {
        signal: controller.signal,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${window.btoa(
            `${value.username}:${value.password}`,
          )}`,
        },
        body: JSON.stringify({
          request: {
            buildingIds: buildingIds,
          },
        }),
      })

      if (window.location.search === '?showlog=true') {
        console.log('building apartments response', response)
      }

      if (!response.ok) {
        let errorResData = await response

        let error = {
          error: true,
          serverError: true,
          refreshError: false,
          connError: false,
          message: errorResData.statusText,
          url: errorResData.url,
          code: errorResData.status,
        }
        return error
      }
      //  returning parsed and reshaped response then calling the get apartments 7d measures in order to
      // append the response to incoming apartment list
      let resData = await response.json()
      if (resData?.payload?.length > 0) {
        let apartmentsMeasuresData = await getApartments7dMeasures(
          value,
          resData?.payload[0]?.apartments,
        )
        if (apartmentsMeasuresData?.length > 0) {
          let apartments = resData?.payload[0]?.apartments
          let final = []
          for (let i = 0; i < apartments?.length; i++) {
            for (let j = 0; j < apartmentsMeasuresData?.length; j++) {
              if (
                apartments[i]?.identifier ===
                apartmentsMeasuresData[j].identifier
              ) {
                final.push({
                  identifier: apartments[i].identifier,
                  name: apartments[i].name,
                  apartmentId: apartments[i].apartmentId,
                  measures7d:
                    apartmentsMeasuresData[j].averageMeasurements || [],
                })
              }
            }
          }
          return final
        } else {
          return resData?.payload[0]?.apartments
        }
      } else if (data[selectedIndex]?.apartments?.length > 0) {
        let apartmentsMeasuresData = await getApartments7dMeasures(
          value,
          data[selectedIndex]?.apartments,
        )
        if (apartmentsMeasuresData?.length > 0) {
          let final = []
          let apartments = data[selectedIndex]?.apartments
          for (let i = 0; i < apartments?.length; i++) {
            for (let j = 0; j < apartmentsMeasuresData?.length; j++) {
              if (
                apartments[i]?.identifier ===
                apartmentsMeasuresData[j].identifier
              ) {
                final.push({
                  identifier: apartments[i].identifier,
                  name: apartments[i].name,
                  apartmentId: apartments[i].apartmentId,
                  measures7d:
                    apartmentsMeasuresData[j].averageMeasurements || [],
                })
              }
            }
          }
          return final
        } else {
          return data[selectedIndex]?.apartments
        }
      } else {
        return data[selectedIndex]?.apartments || []
      }
    }
  } catch (e) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }

    return error
  }
  return {}
}

// Api for appartment 7day measures for a specific set of appartments
export const getApartments7dMeasures = async (userData, apartments) => {
  window.onbeforeunload = null
  try {
    if (apartments?.length > 0) {
      const controller = new AbortController()
      window.onbeforeunload = function () {
        controller.abort()
      }

      setTimeout(() => {
        controller.abort()
      }, 300000)

      let apartmentsIdentifiers = []
      for (let i = 0; i < apartments?.length; i++) {
        apartmentsIdentifiers.push(apartments[i]?.identifier)
      }

      let response = await fetch(
        `${BASE_URL}apartment/sensor/measurements/7d`,
        {
          signal: controller.signal,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Basic ${window.btoa(
              `${userData.username}:${userData.password}`,
            )}`,
          },
          body: JSON.stringify({
            request: {
              apartmentIds: apartmentsIdentifiers,
            },
          }),
        },
      )

      if (window.location.search === '?showlog=true') {
        console.log('apartment 7 day measures response', response)
      }
      if (!response.ok) {
        let errorResData = await response
        let error = {
          error: true,
          message: errorResData.statusText,
          url: errorResData.url,
          code: errorResData.status,
        }

        return error
      }
      // returning parsed response or empty array in case no data
      let appResData = await response.json()
      return appResData?.payload
    } else {
      return []
    }
  } catch (err) {
    if (apartments?.length > 0) {
      return apartments
    } else {
      return []
    }
  }
}

// Api for getting logs for a specific project
export const getLogbook = async (projectId, value) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}logbook/query`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${value.username}:${value.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: [{ projectIdentifier: projectId }],
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('get logbook response', response)
    }

    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response or empty array in case no logs
    let logsData = await response.json()
    if (logsData?.payload?.length > 0) {
      return logsData?.payload
    } else {
      return []
    }
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

// Api to get building 7 day measures
export const getMesauresHistory = async (buildingId, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}building/sensor/measurements/7d`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {
          buildingIds: [buildingId],
        },
      }),
    })
    if (window.location.search === '?showlog=true') {
      console.log('building 7 day measures response', response)
    }

    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    if (resData?.payload?.length > 0) {
      return resData?.payload[0]?.averageMeasurements
    } else {
      return []
    }
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

// API call for home page building measurements
export const getBuilding24hMeasures = async (buildingIds, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}building/sensor/measurements/24h`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {
          buildingIds: buildingIds,
        },
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('building 24h measures response', response)
    }

    // if error in api response return error
    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }
    // returning the parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

// Heating groups api call
export const getHeatingGroups = async (buildingIds, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}configuration/heatingGroups`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {
          buildingIds: buildingIds,
        },
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('heating groups measures response', response)
    }
    // if error returning error
    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

//Api get latest measures of a specific apartment
export const getApartmentMeasure = async (apartmentIds, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(
      `${BASE_URL}apartment/sensor/measurements/latest`,
      {
        signal: controller.signal,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${window.btoa(
            `${userData.username}:${userData.password}`,
          )}`,
        },
        body: JSON.stringify({
          request: {
            apartmentIds: [apartmentIds],
          },
        }),
      },
    )

    if (window.location.search === '?showlog=true') {
      console.log('apartment latest measures response', response)
    }

    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

// API responsible to get the gummiband data
export const getGummiband = async (projectId, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}temporaryOffsetCorrection/query`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {
          projectIdentifiers: [projectId],
        },
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('temporary offset response', response)
    }
    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}
