let textsData = [
  [
    {
      page: 'Alerts',
    },
    {
      title: 'Alerts',
      history: 'View historical data',
      errorMsg: 'Please select a building',
      loadingMsg: 'Loading data',
      emptyMsg: 'No alerts found',
    },
    {
      title: 'Alertes',
      history: 'Afficher les données historiques',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      loadingMsg: 'Chargement des données',
      emptyMsg: 'Aucune alerte trouvée',
    },
    {
      title: 'Alarme',
      history: 'Historische Daten anzeigen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      loadingMsg: 'Daten werden geladen	',
      emptyMsg: 'Keine Alarmmeldung gefunden',
    },
    {
      title: 'Poplachy',
      history: 'Zobrazení historických dat',
      errorMsg: 'Vyberte prosím budovu',
      loadingMsg: 'Načítání dat',
      emptyMsg: 'Nebyly nalezeny žádné výstrahy',
    },
  ],
  [
    {
      page: 'Alerts Details',
    },
    {
      errorMsg: 'Please select an alert type',
    },
    {
      errorMsg: "Veuillez sélectionner un type d'alerte",
    },
    {
      errorMsg: 'Bitte wählen Sie einen Alarmtyp',
    },
    {
      errorMsg: 'Vyberte prosím typ poplachu',
    },
  ],
  [
    {
      page: 'Apartment',
    },
    {
      title: 'Apartments',
      titleMeasurement: 'Measurements',
      titleAlert: 'Alerts',
      severityText: 'Severity',
      allText: 'All',
      selectPlaceholder: 'Select sensor type',
      errorMsg: 'Please select an apartment',
      loadingMsg: 'Loading data',
      sensorTime: 'Latest sensor data',
    },
    {
      title: 'Appartements',
      titleMeasurement: 'Mesures',
      titleAlert: 'Alertes',
      severityText: 'Sévérité',
      allText: 'Tout',
      selectPlaceholder: 'Sélectionnez le type de capteur',
      errorMsg: 'Veuillez sélectionner un appartement',
      loadingMsg: 'Chargement des données',
      sensorTime: 'Dernières données de capteur',
    },
    {
      title: 'Wohnungen',
      titleMeasurement: 'Messungen',
      titleAlert: 'Alarme',
      severityText: 'Schweregrad',
      allText: 'Alles',
      selectPlaceholder: 'Wählen Sie den Sensortyp',
      errorMsg: 'Bitte wählen Sie eine Wohnung aus',
      loadingMsg: 'Daten werden geladen	',
      sensorTime: 'Neueste Sensordaten',
    },
    {
      title: 'Byty',
      titleMeasurement: 'Měření',
      titleAlert: 'Poplachy',
      severityText: 'Závažnost',
      allText: 'Všechny ',
      selectPlaceholder: 'Vyberte typ senzoru',
      errorMsg: 'Vyberte prosím byt',
      loadingMsg: 'Načítání dat',
      sensorTime: 'Nejnovější data senzoru',
    },
  ],
  [
    {
      page: 'Apartments',
    },
    {
      title: 'Apartments',
      tenantText: 'Tenant',
      searchPlaceholder: 'Search by tenant name',
      infoText: 'Info',
      nameText: 'Name',
      errorMsg: 'Please select a building',
      emptyMsg: 'No matching results',
    },
    {
      title: 'Appartements',
      tenantText: 'Locataire',
      searchPlaceholder: 'Recherche par nom de locataire',
      infoText: 'Information',
      nameText: 'Nom',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      emptyMsg: 'Aucun résultat de recherche',
    },
    {
      title: 'Wohnungen',
      tenantText: 'Mieter(in)',
      searchPlaceholder: 'Suche nach Mieternamen',
      infoText: 'Informationen',
      nameText: 'Name',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      emptyMsg: 'Keine Treffer gefunden',
    },
    {
      title: 'Byty',
      tenantText: 'Nájemce',
      searchPlaceholder: 'Vyhledávání podle názvu nájemce',
      infoText: 'Informace',
      nameText: 'Jméno',
      errorMsg: 'Vyberte prosím budovu',
      emptyMsg: 'Nebyly nalezeny žádné výsledky',
    },
  ],
  [
    {
      page: 'Login',
    },
    {
      header: 'Sign in',
      emailText: 'Username',
      passwordText: 'Password',
      btnText: 'Sign In',
      forgetText: 'Forgot password?',
      loadingMsg: 'Loading data, please wait',
      serverErrMsg: 'Server error',
      versionErrMsg: 'Your app is outdated please re-login',
      connectionErrMsg: 'Unable to contact Ecco2 server',
      authErrMsg: 'Login failed: username or password not valid',
      roleErrMsg:
        'Your role does not allow to use the app. Kindly reach out to your ECCO2 contact',
      passRequiredText: 'Password is required',
      userRequiredText: 'Username is required',
    },
    {
      header: 'Connectez-vous',
      emailText: 'Identifiant',
      passwordText: 'Mot de passe',
      btnText: "S'identifier",
      forgetText: 'Mot de passe oublié?',
      loadingMsg: 'Chargement de données, veuillez patienter',
      serverErrMsg: 'Erreur du serveur',
      roleErrMsg: `Votre rôle ne vous permet pas d'utiliser l'application. Veuillez contacter votre contact ECCO2`,
      versionErrMsg:
        'Votre application est obsolète. veuillez vous reconnecter',
      connectionErrMsg: 'Impossible de contacter le serveur Ecco2',
      authErrMsg:
        "La connexion a échoué: nom d'utilisateur ou mot de passe non valide",
      passRequiredText: 'Mot de passe requis',
      userRequiredText: 'Identifiant est nécessaire',
    },
    {
      header: 'Einloggen',
      emailText: 'Benutzername',
      passwordText: 'Passwort',
      btnText: 'Anmeldung',
      forgetText: 'Passwort vergessen?',
      loadingMsg: 'Laden der Daten, bitte warten Sie',
      serverErrMsg: 'Serverfehler',
      roleErrMsg:
        'Ihre Rolle erlaubt es Ihnen nicht, die App zu nutzen. Wenden Sie sich bitte an Ihren ECCO2-Kontakt',
      versionErrMsg: 'Ihre App ist veraltet. Bitte loggen sie sich erneut ein',
      connectionErrMsg: 'Ecco2-Server kann nicht kontaktiert werden',
      authErrMsg:
        'Anmeldung fehlgeschlagen: Benutzername oder Passwort nicht gültig',
      passRequiredText: 'Passwort wird benötigt',
      userRequiredText: 'Benutzername wird benötigt',
    },
    {
      header: 'Přihlásit se',
      emailText: 'Uživatelské jméno',
      passwordText: 'Heslo',
      btnText: 'Přihlásit se',
      forgetText: 'Zapomněli jste heslo?',
      loadingMsg: 'Načítám data, čekejte prosím',
      serverErrMsg: 'Chyba serveru',
      roleErrMsg:
        'Vaše role neumožňuje aplikaci používat. Obraťte se laskavě na kontaktní osobu ECCO2',
      versionErrMsg: 'Vaše aplikace je zastaralá. Přihlaste se prosím znovu',
      connectionErrMsg: 'Nejste připojen k Internetu',
      authErrMsg:
        'Připojení se nezdařilo: neplatné uživatelské jméno nebo heslo',
      passRequiredText: 'Heslo je povinné',
      userRequiredText: 'Uživatelské jméno je povinné',
    },
  ],
  [
    {
      page: 'Building',
    },
    {
      searchPlaceholder: 'Search by apartment, tenant, tag,...',
      viewallText: 'view all',
      apartmentsBtnText: 'Apartments',
      heatingGroupsBtnText: 'Heating Groups',
      alertsBtnText: 'Alerts',
      measuresText: 'Measures (Last 7 Days)',
      temperatureText: 'Temperature',
      humidityText: 'Humidity',
      logBtnText: 'Logs',
      sensorBtnText: 'Sensors',
      emrBtnText: 'Energy measurements',
      errorMsg: 'Please select a building',
      emptyMsg: 'No matching results',
      graphMsg: 'No graph data',
      loadingMsg: 'Loading data',
      numberErrMsg: 'Please enter a number',
      bldgLegendText: 'Average',
      noSensors: 'This building has no sensor',
    },
    {
      searchPlaceholder: 'Recherche par appartement, locataire, tag, ...',
      viewallText: 'Afficher tout',
      apartmentsBtnText: 'Appartements',
      heatingGroupsBtnText: 'Groupes de chauffe',
      alertsBtnText: 'Alertes',
      measuresText: 'Mesures (7 Derniers Jours)',
      temperatureText: 'Température',
      humidityText: 'Humidité',
      logBtnText: 'Historique',
      sensorBtnText: 'Capteurs',
      emrBtnText: 'Compteurs d’énergie',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      emptyMsg: 'Aucun résultat de recherche',
      loadingMsg: 'Chargement des données',
      bldgLegendText: 'Moyenne',
      graphMsg: 'Données manquantes',
      noSensors: "Ce bâtiment n'a pas de capteur",
    },
    {
      searchPlaceholder: 'Suche nach Wohnung, Mieter, Tag, ...',
      viewallText: 'Alle ansehen',
      apartmentsBtnText: 'Wohnungen',
      heatingGroupsBtnText: 'Heizgruppen',
      alertsBtnText: 'Alarme',
      measuresText: 'Messwerte (letzte 7 Tage)',
      temperatureText: 'Temperatur',
      humidityText: 'Feuchtigkeit',
      logBtnText: 'Protokoll',
      sensorBtnText: 'Sensoren',
      emrBtnText: 'Energiemessungen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      emptyMsg: 'Keine Treffer gefunden',
      loadingMsg: 'Daten werden geladen	',
      bldgLegendText: 'Durchschnitt',
      graphMsg: 'Keine Daten für das Diagramm gefunden',
      noSensors: 'Dieses Gebäude hat keinen Sensor',
    },
    {
      searchPlaceholder: 'Vyhledávání podle bytu, nájemce, štítku, ...',
      viewallText: 'Zobrazit vše',
      apartmentsBtnText: 'Byty',
      heatingGroupsBtnText: 'Topné skupiny',
      alertsBtnText: 'Poplachy',
      measuresText: 'Naměřené hodnoty (posledních 7 dní)',
      temperatureText: 'Teplota',
      humidityText: 'Vlhkost',
      logBtnText: 'Historie ',
      sensorBtnText: 'Senzory',
      emrBtnText: 'Měření spotřeby energie',
      errorMsg: 'Vyberte prosím budovu',
      emptyMsg: 'Nebyly nalezeny žádné výsledky',
      graphMsg: 'Nebyla nalezena žádná data pro graf',
      loadingMsg: 'Načítání dat',
      numberErrMsg: 'Prosím doplňte číslo',
      bldgLegendText: 'Průměr',
      noSensors: 'Tato budova nemá žádný senzor',
    },
  ],
  [
    {
      page: 'EMR',
    },
    {
      title: 'Meter',
      testText: 'Page is still under development',
      modalTitle: 'Meter Reading',
      modalLabel: 'Meter Value in',
      modalLabel1: 'Date of meter reading',
      modalPlaceholder: 'Value read on meter',
      btn1Text: 'Scan',
      btn2Text: 'Cancel',
      btn3Text: 'Add',
      btn4Text: 'Accept',
      errorMsg: 'Please select a building',
      emptyMsg: 'No meters to be read',
      submitText: 'Submit',
      emrErrText: 'Meter value is required',
      successMsg: 'Meter value saved successfully',
      failedMsg:
        'No network conection at the moment. Your input is saved locally and will be forwarded automatically later',
      checkMsg:
        'Your entry does not seem plausible. Please check and confirm that the value is',
      minErrMsg: 'Value must be greater than 0',
      numberErrMsg: 'Please enter a numeric value',
    },
    {
      title: 'Compteur',
      testText: 'Page encore en développement',
      modalTitle: 'Relevé du compteur',
      modalLabel: 'Valeur lue sur le compteur en',
      modalLabel1: 'Date du relevé de la mesure',
      modalPlaceholder: 'Valeur lue sur le compteur',
      btn1Text: 'Scanner',
      btn2Text: 'Annuler',
      btn3Text: 'Ajouter',
      btn4Text: 'Accepter',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      emptyMsg: "Aucun compteur d'énergie à lire",
      submitText: 'Enregistrer',
      emrErrText: "La valeur du compteur d'énergie est requise",
      successMsg: 'Valeur sauvegardée',
      failedMsg:
        'Pas de connexion réseau en ce moment. Votre saisie est stockée localement et sera transmise plus tard automatiquement',
      checkMsg:
        "La valeur saisie ne semble pas plausible. Veuillez vérifier et confirmer que c'est bien",
      numberErrMsg: 'Veuillez saisir un nombre',
      minErrMsg: 'La valeur doit être supérieure à 0',
    },
    {
      title: 'Zähler',
      testText: 'Seite befindet sich derzeit in der Entwicklung',
      modalTitle: 'Zählerstand',
      modalLabel: 'Wert am Energiezähler in',
      modalLabel1: 'Datum des Zählerstandes',
      modalPlaceholder: 'Abgelesener Wert am Zähler',
      btn1Text: 'Scan',
      btn2Text: 'Abbrechen',
      btn3Text: 'Hinzufügen',
      btn4Text: 'Annehmen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      emptyMsg: 'Keine Energiezähler zum lesen',
      submitText: 'Einreichen',
      emrErrText: 'Der Wert des Energiezählers ist erforderlich',
      successMsg: 'Der Wert des Energiezählers wurde erfolgreich gespeichert',
      failedMsg:
        'Momentan gibt es keine Netzwerkverbindung. Ihre Daten sind lokal gespeichert und werden später automatisch hochgeladen',
      checkMsg:
        'Das eingegebene Wert scheint nicht plausibel zu sein. Bitte prüfen und bestätigen Sie diese Eingabe :',
      numberErrMsg: 'Bitte geben Sie eine Zahl ein',
      minErrMsg: 'Wert muss größer als 0 sein',
    },
    {
      title: 'Měřič tepla',
      testText: 'Stránka je stále ve vývoji',
      modalTitle: 'Odečet ',
      modalLabel: 'Odečtená hodnota v',
      modalLabel1: 'Datum odečtu',
      modalPlaceholder: 'Stav měřiče tepla',
      btn1Text: 'Skenovat',
      btn2Text: 'Zrušit',
      btn3Text: 'Přidat',
      btn4Text: 'Přijmout',
      errorMsg: 'Vyberte prosím budovu',
      emptyMsg: 'Žádný měřič tepla k odečtu',
      submitText: 'Uložit',
      emrErrText: 'Hodnota měřiče tepla je povinná',
      successMsg: 'Hodnota měřiče tepla byla  úspěšně uložena',
      failedMsg:
        'V současné době není k dispozici žádné síťové připojení. Vaše data jsou uložena místně a později se automaticky nahrají',
      checkMsg:
        'Zadaná hodnota se nezdá být věrohodná. Zkontrolujte ji a potvrďte',
      minErrMsg: 'Hodnota musí být větší než 0',
      numberErrMsg: 'Zadejte číselnou hodnotu',
    },
  ],
  [
    {
      page: 'error',
    },
    {
      errorMsg: 'Oops, it seems that this page does not exist here.',
    },
    {
      errorMsg: "Oups, il semble que cette page n'existe pas ici.",
    },
    {
      errorMsg: 'Hoppla, es scheint dass diese Seite hier nicht existiert.',
    },
    {
      errorMsg: 'Jejda, vypadá to, že tato stránka zde neexistuje.',
    },
  ],
  [
    {
      page: 'Global Indoor Climate',
    },
    {
      history: 'View Historical data',
      errorMsg: 'Please select a building',
    },
    {
      history: 'Afficher les données historiques',
      errorMsg: 'Veuillez sélectionner un bâtiment',
    },
    {
      history: 'Historische Daten anzeigen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
    },
    {
      history: 'Zobrazení historických dat',
      errorMsg: 'Vyberte prosím budovu',
    },
  ],
  [
    {
      page: 'Heating Groups',
    },
    {
      title: 'Heating groups',
      history: 'View Historical data',
      errorMsg: 'Please select a building',
      loadingMsg: 'Loading data',
      emptyMsg: 'No heating groups found',
    },
    {
      title: 'Groupes de chauffe',
      history: 'Afficher les données historiques',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      loadingMsg: 'Chargement des données',
      emptyMsg: 'Aucun groupe de chauffe trouvée',
    },
    {
      title: 'Heizgruppen',
      history: 'Historische Daten anzeigen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      loadingMsg: 'Daten werden geladen	',
      emptyMsg: 'Keine Heizgruppen gefunden',
    },
    {
      title: 'Topné skupiny',
      history: 'Zobrazení historických dat',
      errorMsg: 'Vyberte prosím budovu',
      loadingMsg: 'Načítání dat',
      emptyMsg: 'Nebyly nalezeny žádné topné skupiny',
    },
  ],
  [
    {
      page: 'Heating Group',
    },
    {
      titleMeasurement: 'Measurements',
      errorMsg: 'Please select a heating group',
      emptyMsg: 'No heating groups found',
    },
    {
      titleMeasurement: 'Des Mesures',
      errorMsg: 'Veuillez sélectionner un groupe de chauffe',
      emptyMsg: 'Aucun groupe de chauffage trouvé',
    },
    {
      titleMeasurement: 'Messungen',
      errorMsg: 'Bitte wählen Sie eine Heizgruppe',
      emptyMsg: 'Keine Heizgruppen gefunden',
    },
    {
      titleMeasurement: 'Měření',
      errorMsg: 'Vyberte prosím topnou skupinu',
      emptyMsg: 'Nebyly nalezeny žádné topné skupiny',
    },
  ],
  [
    {
      page: 'Home',
    },
    {
      loadingMsg: 'Loading, please wait',
      emptyMsg: 'No matching results',
      searchPlaceholder: 'Building name, type, address, tenant',
      noSensors: 'This building has no sensor',
      emrTooltip: 'EMR',
    },
    {
      loadingMsg: 'Chargement, veuillez patienter',
      emptyMsg: 'Aucun résultat de recherche',
      searchPlaceholder: 'Bâtiment, type, adresse, locataire',
      noSensors: "Ce bâtiment n'a pas de capteur",
      emrTooltip: 'EMR',
    },
    {
      loadingMsg: 'Lädt, bitte warten Sie',
      emptyMsg: 'Keine Treffer gefunden',
      searchPlaceholder: 'Gebäudename, Typ, Adresse, Mieter',
      noSensors: 'Dieses Gebäude hat keinen Sensor',
      emrTooltip: 'EMR',
    },
    {
      loadingMsg: 'Načítám, čekejte prosím',
      emptyMsg: 'Nebyly nalezeny žádné výsledky',
      searchPlaceholder: 'Název, typ, adresa, nájemce budovy',
      noSensors: 'Tato budova nemá senzor',
      emrTooltip: 'EMR',
    },
  ],
  [
    {
      page: 'Logs',
    },
    {
      title: 'Event Log',
      testText: 'Page under development',
      modalTitle: 'Add a log entry',
      typeMsg: 'Type',
      selectMsg: 'Select a type',
      visibilityText: 'Visibility',
      adminText: 'Admin',
      publicText: 'Public',
      commentMsg: 'Log entry',
      commentText: 'Type a log entry',
      modalLabel1: 'Title',
      modalPlaceholder1: 'Add a title',
      modalLabel2: 'Text',
      modalPlaceholder2: 'Add a log entry',
      btn1Text: 'Cancel',
      btn2Text: 'Add',
      errorMsg: 'Please select a building',
      emptyMsg: 'No log entries found',
      submitText: 'Submit',
      loadingMsg: 'Loading data',
      successMsg: 'Log entry saved successfully',
      failedMsg:
        'No network conection at the moment. Your input is saved locally and will be forwarded automatically later',
    },
    {
      title: "Journal d'évènements",
      testText: 'Page en développement',
      modalTitle: 'Ajouter un évènement',
      typeMsg: 'Type',
      selectMsg: 'Sélectionnez un type',
      visibilityText: 'Visibilité',
      adminText: 'Administrateur',
      publicText: 'Public',
      commentMsg: 'Evènement',
      commentText: 'Saisissez un évènement',
      modalLabel1: 'Titre',
      modalPlaceholder1: 'Ajouter un titre',
      modalLabel2: 'Texte',
      modalPlaceholder2: 'Ajouter une évènement',
      btn1Text: 'Annuler',
      btn2Text: 'Ajouter',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      emptyMsg: 'Aucun évènement trouvé',
      submitText: 'Sauvegarder',
      loadingMsg: 'Chargement des données',
      successMsg: 'Evènement sauvegardé',
      failedMsg:
        'Pas de connexion réseau en ce moment. Votre saisie est stockée localement et sera transmise plus tard automatiquement',
    },
    {
      title: 'Ereignissprotokoll',
      testText: 'Diese Seite befindet sich derzeit in Entwicklung',
      modalTitle: 'Protokolleintrag hinzufügen',
      typeMsg: 'Typ',
      selectMsg: 'Wählen Sie einen Typ aus',
      visibilityText: 'Sichtbarkeit',
      adminText: 'Administrator',
      publicText: 'Öffentlich',
      commentMsg: 'Protokolleintrag',
      commentText: 'Geben Sie einen Protokolleintrag ein',
      modalLabel1: 'Titel',
      modalPlaceholder1: 'Titel hinzufügen',
      modalLabel2: 'Text',
      modalPlaceholder2: 'Protokolleintrag hinzufügen',
      btn1Text: 'Abbrechen',
      btn2Text: 'Hinzufügen',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      emptyMsg: 'Keine Einträge gefunden',
      submitText: 'Einreichen',
      loadingMsg: 'Daten werden geladen',
      successMsg: 'Protokolleintrag erfolgreich gespeichert',
      failedMsg:
        'Momentan gibt es keine Netzwerkverbindung. Ihre Daten sind lokal gespeichert und werden später automatisch hochgeladen',
    },
    {
      title: 'Protokol událostí',
      testText: 'Stránka ve vývoji',
      modalTitle: 'Přidání položky protokolu',
      typeMsg: 'Typ',
      selectMsg: 'Vyberte typ',
      visibilityText: 'Viditelnost',
      adminText: 'Administrátor',
      publicText: 'Veřejnost',
      commentMsg: 'Záznam protokolu',
      commentText: 'Zadejte položku protokolu',
      modalLabel1: 'Název',
      modalPlaceholder1: 'Přidání názvu',
      modalLabel2: 'Text',
      modalPlaceholder2: 'Přidání položky protokolu',
      btn1Text: 'Zrušit',
      btn2Text: 'Přidat',
      errorMsg: 'Vyberte prosím budovu',
      emptyMsg: 'Nebyly nalezeny žádné události',
      submitText: 'Podat',
      loadingMsg: 'Načítání dat',
      successMsg: 'Záznam protokolu byl úspěšně uložen',
      failedMsg:
        'V současné době není k dispozici žádné síťové připojení.\r\n Vaše data jsou uložena místně a později se automaticky nahrají',
    },
  ],
  [
    {
      page: 'Sensors',
    },
  ],
  [
    {
      page: 'Header',
    },
    {
      homeBtnText: 'home',
      menuBtnText: 'menu',
      projectBtnText: 'Project',
      projectsBtnText: 'Projects',
      buildingsBtnText: 'All buildings',
      buildingBtnText: 'Building',
      apartmentsBtnText: 'Apartments',
      apartmentBtnText: 'Apartment',
      heatingGroupsBtnText: 'Heating groups',
      heatingSystemBtnText: 'Heating system',
      globalIndoorClimateBtnText: 'Indoor Climate',
      alertsBtnText: 'Alerts',
      alertshistoryBtnText: 'Alerts history',
      logBtnText: 'Logs',
      sensorBtnText: 'Sensors',
      emrBtnText: 'Meters',
      errorMsg: 'Please select a building',
      languageText: 'Language',
      englishText: 'EN',
      frenchText: 'FR',
      germanText: 'DE',
      czechText: 'CS',
      logoutText: 'Log Out',
      loadingMsg: 'Loading data',
      temporaryOffsetBtnText: 'Indoor temperature change',
      favoritesBtnText: 'Favorites',
    },
    {
      homeBtnText: 'Page principale',
      menuBtnText: 'menu',
      projectBtnText: 'Project',
      projectsBtnText: 'Projects',
      buildingsBtnText: ' Tous les bâtiments',
      buildingBtnText: 'Bâtiment',
      apartmentsBtnText: 'Appartements',
      apartmentBtnText: 'Appartement',
      heatingGroupsBtnText: 'Groupes de chauffe',
      heatingSystemBtnText: 'Chaufferie',
      globalIndoorClimateBtnText: 'Climat intérieur',
      alertsBtnText: 'Alertes',
      alertshistoryBtnText: 'Historique des alertes',
      logBtnText: 'Notes',
      sensorBtnText: 'Capteurs',
      emrBtnText: 'Relevé du compteur',
      errorMsg: 'Veuillez sélectionner un bâtiment',
      languageText: 'Langue',
      englishText: 'EN',
      frenchText: 'FR',
      germanText: 'DE',
      czechText: 'CS',
      logoutText: 'Déconnexion',
      loadingMsg: 'Chargement de données',
      temporaryOffsetBtnText: 'Correction de la température intérieure',
      favoritesBtnText: 'Favoris',
    },
    {
      homeBtnText: 'Startseite',
      menuBtnText: 'Menü',
      projectBtnText: 'Projekt',
      projectsBtnText: 'Projekte',
      buildingsBtnText: 'Alle Gebäude',
      buildingBtnText: 'Gebäude',
      apartmentsBtnText: 'Wohnungen',
      apartmentBtnText: 'Wohnung',
      heatingGroupsBtnText: 'Heizgruppen',
      heatingSystemBtnText: 'Heizanlage',
      globalIndoorClimateBtnText: 'Raumklima',
      alertsBtnText: 'Alarme',
      alertshistoryBtnText: 'Verlauf der Alarme',
      logBtnText: 'Protokolle',
      sensorBtnText: 'Sensoren',
      emrBtnText: 'Zählerstand',
      errorMsg: 'Bitte wählen Sie ein Gebäude aus',
      languageText: 'Sprache',
      englishText: 'EN',
      frenchText: 'FR',
      germanText: 'DE',
      czechText: 'CS',
      logoutText: 'Ausloggen',
      loadingMsg: 'Daten werden geladen	',
      temporaryOffsetBtnText: 'Anpassung der Innenraum-Temperatur',
      favoritesBtnText: 'Favoriten',
    },
    {
      homeBtnText: 'Hlavní stranka',
      menuBtnText: 'Menu',
      projectBtnText: 'Projekt',
      projectsBtnText: 'Projekty',
      buildingsBtnText: 'Všechny budovy',
      buildingBtnText: 'Budova',
      apartmentsBtnText: 'Byty',
      apartmentBtnText: 'Byt',
      heatingGroupsBtnText: 'Topné skupiny',
      heatingSystemBtnText: 'Vytápěcí systém',
      globalIndoorClimateBtnText: 'Vnitřní klima',
      alertsBtnText: 'Poplach',
      alertshistoryBtnText: 'Historie poplachu',
      logBtnText: 'Topné skupiny',
      sensorBtnText: 'Senzory',
      emrBtnText: 'Elektroměr',
      errorMsg: 'Vyberte prosím budovu',
      languageText: 'Jazyk',
      englishText: 'EN',
      frenchText: 'FR',
      germanText: 'DE',
      czechText: 'CS',
      logoutText: 'Odhlásit se',
      loadingMsg: 'Načítání dat',
      temporaryOffsetBtnText: 'Nastavení vnitřní teploty',
      favoritesBtnText: 'Oblíbené položky',
    },
  ],
  [
    {
      page: 'Coming Soon',
    },
    {
      message: 'Under development',
      btnMessage: 'Coming Soon',
      hrefMessage: 'Back to home',
    },
    {
      message: 'En développement',
      btnMessage: 'Bientôt disponible',
      hrefMessage: 'Retour à la page principale',
    },
    {
      message: 'In entwicklung',
      btnMessage: 'Bald verfügbar',
      hrefMessage: 'Zurück zur Startseite',
    },
    {
      message: 'Ve vývoji',
      btnMessage: 'Již brzy',
      hrefMessage: 'Zpět na hlavní stránku',
    },
  ],
  [
    {
      page: 'App.js',
    },
    {
      loadingMsg: 'Loading data, please wait',
    },
    {
      loadingMsg: 'Chargement de données, veuillez patienter',
    },
    {
      loadingMsg: 'Daten werden geladen, bitte warten Sie',
    },
    {
      loadingMsg: 'Načítání dat, čekejte prosím',
    },
  ],
  [
    {
      page: 'Indoor temperature change',
    },
    {
      temporaryOffsetBtnText: 'Indoor temperature change',
      temperature: 'Adjust Indoor T',
      maintain: 'Maintain for',
      decay: 'Decay after',
      hour: ' hour(s)',
      hours: ' hour(s)',
      day: ' day',
      days: ' days',
      submit: 'Submit',
      stop: 'Stop',
      modalSuccessMsg: 'Indoor temperature change updated successfully',
      modalErrorMsg: 'Something went wrong',
      emptyMsg: 'No entries found',
      endDate: 'Temporary offset ends on',
      at: 'at',
      error: 'Offset needs to be maintained for at least 2 hours.',
      errorTemp: 'Offset needs to be different from 0.',
    },
    {
      temporaryOffsetBtnText: 'Correction de la température intérieure',
      temperature: 'Ajuster T intérieure',
      maintain: 'Maintenir',
      decay: 'Retour après',
      hour: ' heure(s)',
      hours: ' heure(s)',
      day: ' jour',
      days: ' jours',
      submit: 'Enregistrer',
      stop: 'Arrêt',
      modalSuccessMsg:
        'Changement de la température intérieure mis à jour avec succès',
      modalErrorMsg: "Quelque chose s'est mal passé",
      emptyMsg: 'Aucune entrée trouvée',
      endDate: 'Le décalage temporaire se termine le',
      at: 'à',
      error: 'Le décalage doit être maintenu pendant au moins 2 heures.',
      errorTemp: 'Le décalage doit être différent de 0.',
    },
    {
      temporaryOffsetBtnText: 'Anpassung der Innenraum-Temperatur',
      temperature: 'Innenraum T anpassen',
      maintain: 'Beibehalten ',
      decay: 'Zerfall nach',
      hour: ' stunde(n)',
      hours: ' stunde(n)',
      day: ' Tag',
      days: ' Tage',
      submit: 'Einreiche ',
      stop: 'Stoppen',
      modalSuccessMsg: 'Innentemperaturänderung erfolgreich aktualisiert',
      modalErrorMsg: 'Etwas ist schief gelaufen',
      emptyMsg: 'Keine Einträge gefunden',
      endDate: 'Der Vorübergehender Ausgleich endet am',
      at: 'beim',
      error:
        'Die Verschiebung sollte mindestens zwei Stunden lang aufrechterhalten werden.',
      errorTemp: 'Der Offset muss von 0 verschieden sein.',
    },
    {
      temporaryOffsetBtnText: 'Nastavení vnitřní teploty',
      temperature: 'Nastavit vnitřní T',
      maintain: 'Udržovat po dobu',
      decay: 'Návrat po',
      hour: 'Hodin(y)',
      hours: 'Hodin(y)',
      day: 'Den',
      days: 'Dny',
      submit: 'Podat',
      stop: 'Zastavit',
      modalSuccessMsg: 'Úspěšná aktualizace změny vnitřní teploty',
      modalErrorMsg: 'Něco se pokazilo',
      emptyMsg: 'Nebyly nalezeny žádné položky',
      endDate: 'Dočasná kompenzace končí dne',
      at: 'v hod.',
      error: 'Časová prodleva musí být zachována po dobu nejméně 2 hodin.',
      errorTemp: 'Offset musí být jiný než 0.',
    },
  ],
]

module.exports = textsData
